import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import { name, version } from '../package.json'
;(function versions(name, version, window) {
	const versions = (window.versions = window.versions || {})
	/* istanbul ignore next */
	const values = new Set(versions[name])
	values.add(version)
	versions[name] = Array.from(values)
})(name, version, window)

export {
	setOrigin,
	setContext,
	setAppContext,
	setAuthToken,
	setOnRedirectCallback,
	setConfiguration,
	createPostRequest,
	pathToUrl,
	UPLOAD,
	DOWNLOAD,
	POST
} from './authority'

export { authorityFactory, defaultConfig } from './authorityFactory'

export { CONTENT_TYPE } from './response'
