/**
 * IE requires body to be serialized to string
 * ensure the body is a FormData or String data type
 * @param body - Object, FormData or String
 * @returns {*} - JSON.stringify(Object), FormData or String
 */
export function serializeRequestBody(body) {
	if (body instanceof FormData) {
		return body
	}
	if (typeof body === 'string') {
		return body
	}
	if (body === undefined) {
		return body
	}
	// if body is not FormData or String then
	// serialize it to JSON string...
	return JSON.stringify(body)
}
