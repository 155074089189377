import FileSaver from 'file-saver'
import { doFetch } from './fetch'
import { getHeader } from './response'

export async function DOWNLOAD_VIA_POST(url, request, processResponseError) {
	const response = await doFetch(url, request)
	if (response.ok === true) {
		const disposition = getHeader(response.headers, 'Content-Disposition')
		const fileName = getFileNameFromDisposition(disposition)
		if (!fileName) {
			throw new Error('Response is missing filename in content-disposition header.')
		}
		const blob = await response.blob()
		return FileSaver.saveAs(blob, fileName)
	}
	return processResponseError(response)
}

export function getFileNameFromDisposition(disposition) {
	if (typeof disposition === 'string') {
		// parse file name from `attachment; filename="filename.jpg"`
		//https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
		//https://stackoverflow.com/questions/23054475/javascript-regex-for-extracting-filename-from-content-disposition-header/23054920
		const filenameRegex = /.*filename[^;=\n]*=((['"])(.*?\2)|[^;\n]*)/
		return disposition.replace(filenameRegex, '$1').replace(/['";]/g, '')
	}
	return ''
}
