/**
 * fetch API does not support progress. polyfill to provide a fetch API compatible
 * upload request with progress API.
 *
 * Usage:
 * POST_WITH_PROGRESS('url', authority.createPostRequest(body), authority.processResponse)
 *        .progress(event => console.log(event))
 *        .then(success => console.log('success', success))
 *        .catch(error => console.log('error', error)
 *
 * const requestPromise = POST_WITH_PROGRESS(...)
 * // do something then decide to cancel request
 * requestPromise.cancel()
 *
 * @param url - the api end point
 * @param request - the fetch API request options
 * @param processResponse - the authority.processResponse handler
 * @param xhr - the XMLHttpRequest instance used for testing to inject a mock
 * @returns {Promise<any>} - the Promise API with .progress(callback) and .cancel()
 * @constructor
 */
export function POST_WITH_PROGRESS(url, request, processResponse, xhr = new XMLHttpRequest()) {
	const progress = []

	function handleProgressEvent(event) {
		progress.forEach(callback => notifyProgress(event, callback))
	}

	let isUserCancelled = false

	const promise = new Promise((resolve, reject) => {
		async function createResponseAndNotify() {
			if (isUserCancelled) {
				reject({ ok: false, isUserCancelled })
				return
			}
			const response = createFetchLikeResponse(xhr)
			try {
				const json = await processResponse(response)
				resolve(json)
			} catch (error) {
				reject(error)
			}
		}
		xhr.open('POST', url, true)
		addHeaders(xhr, request.headers)
		xhr.upload.onloadstart = handleProgressEvent
		xhr.upload.onprogress = handleProgressEvent
		xhr.upload.onloadend = handleProgressEvent
		xhr.onload = function onLoadComplete() {
			return createResponseAndNotify()
		}
		xhr.upload.onerror = function onError() {
			return createResponseAndNotify()
		}
		xhr.upload.onabort = function onAbort() {
			return createResponseAndNotify()
		}
		xhr.send(request.body)
	})
	promise.progress = function onProgress(callback) {
		progress.push(callback)
		return promise
	}
	promise.cancel = function cancel() {
		// must set this flag before calling abort
		isUserCancelled = true
		xhr.abort()
		progress.length = 0
	}
	return promise
}

export function createFetchLikeResponse(xhr) {
	const { status, statusText, responseText } = xhr
	return {
		get status() {
			return status
		},
		get statusText() {
			return statusText
		},
		get headers() {
			return {
				get: function(name) {
					try {
						return xhr.getResponseHeader(name)
					} catch (error) {
						console.error(`unable to get response header: ${name}`, error)
						return ''
					}
				}
			}
		},
		get ok() {
			return status < 400 && status > 0
		},
		json() {
			try {
				const json = JSON.parse(responseText)
				return Promise.resolve(json)
			} catch (error) {
				console.error('unable to parse responseText as json', responseText)
				return Promise.reject(error)
			}
		},
		text() {
			return Promise.resolve(responseText)
		}
	}
}

export function notifyProgress(event, callback) {
	if (event.lengthComputable) {
		callback({
			total: event.total,
			loaded: event.loaded
		})
	}
}

export function addHeaders(xhr, headers) {
	headers.forEach((value, name) => xhr.setRequestHeader(name, value))
}
