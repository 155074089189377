export const CONTENT_TYPE = 'application/json'

export function getHeader(headers, headerName, defaultValue) {
	if (headers && headers.get) {
		const value = headers.get(headerName)
		if (value) {
			return value
		}
		return defaultValue
	}
	return defaultValue
}

export async function processResponse(response) {
	const contentType = getContentType(response)
	if (/json/.test(contentType)) {
		const json = await response.json()
		if (response.ok) {
			return json
		}
		throw json
	}
	if (typeof response.text === 'function') {
		const text = await response.text()
		if (/application\/text/.test(contentType)) {
			if (response.ok) {
				return JSON.parse(text)
			}
		}
		throw { status: response.status, ok: response.ok, error: text }
	}
	throw { error: 'unable to process response', response }
}

export function getContentType({ headers }) {
	return getHeader(headers, 'Content-Type', CONTENT_TYPE)
}
