// https://wts-jira.wellsfargo.com/browse/EFMYA-7869

import { getHeader } from './response'

export const SIGN_OUT_PATH = 'SignOut.html'

export function redirect({ redirected, type, status, headers, url }) {
	if (redirected) {
		// response is resolved to final url
		return url || SIGN_OUT_PATH
	}
	if (/redirect/.test(type)) {
		// response is the 302 so use the header location
		return getHeader(headers, 'location', SIGN_OUT_PATH)
	}
	if (status === 302) {
		// note: fetch api does not expose a 302 status
		// we put this if block in just for good measure
		return getHeader(headers, 'location', SIGN_OUT_PATH)
	}
	if (status === 401) {
		// response is not authorized
		return SIGN_OUT_PATH
	}
	if (status === 403) {
		// response is forbidden
		return SIGN_OUT_PATH
	}
	// no redirect
	return ''
}
