import { authorityFactory, defaultConfig } from './authorityFactory'

const ModuleInstance = authorityFactory()

export const {
	setOrigin,
	setContext,
	setAppContext,
	setAuthToken,
	setOnRedirectCallback,
	setConfiguration,
	createPostRequest,
	pathToUrl,
	UPLOAD,
	DOWNLOAD,
	POST
} = ModuleInstance

export { defaultConfig }
