const browserDocument = document

export function scrapeHeaderFromDocument(tags, document = browserDocument) {
	const values = tags
		.map(id => document.getElementById(id))
		.map(meta => meta && meta.getAttribute('content'))
		// check attribute value exists
		.filter(i => !!i)
	if (values.length === 2) {
		return {
			name: values[0],
			value: values[1]
		}
	}
}

export function scrapeAndAppendHeader(nameId, valueId, append, document) {
	const header = scrapeHeaderFromDocument([nameId, valueId], document)
	if (header) {
		append(header.name, header.value)
	}
}
