import urlJoin from 'proper-url-join'
import { doFetch } from './fetch'
import { redirect } from './redirect'
import { CONTENT_TYPE, processResponse } from './response'
import { POST_WITH_PROGRESS } from './upload'
import { DOWNLOAD_VIA_POST } from './download'
import { scrapeAndAppendHeader } from './scrapeAndAppendHeader'
import { updateDocumentLocation } from './updateDocumentLocation'
import { getTimestamp } from './timestamp'
import { serializeRequestBody } from './serializeRequestBody'

export const defaultConfig = Object.freeze({
	authHeaderNameId: 'wf-efmya-token-header-name',
	authHeaderValueId: 'wf-efmya-token-header-value',
	csrfHeaderNameId: '__csrfheader',
	csrfHeaderValueId: '__csrftoken',
	senderHostName: 'localhost',
	senderMessageId: '',
	senderApplicationId: ''
})

export function authorityFactory({ origin = '', context = '', configuration = {} } = {}) {
	const config = { ...defaultConfig }

	let _origin = origin
	let _context = context
	let _onRedirectCallback = updateDocumentLocation
	let _authorizationHeaderValue
	let _authorizationHeaderValueSet = false

	setConfiguration(configuration)

	function setOrigin(origin) {
		_origin = origin
	}

	function setContext(context) {
		_context = context
	}

	function setAppContext(appContext) {
		/* istanbul ignore next */
		if (process.env.NODE_ENV === 'development') {
			/* eslint-disable-next-line no-console */
			console.log(`setAppContext() is deprecated as of v1.0.3 use setContext()`)
		}
		_context = appContext
	}

	function setAuthToken(authToken) {
		_authorizationHeaderValue = authToken
		_authorizationHeaderValueSet = true
	}

	function setOnRedirectCallback(onRedirectCallback) {
		_onRedirectCallback = onRedirectCallback
	}

	function setConfiguration(updateConfig) {
		const keys = Object.keys(defaultConfig)
		keys.forEach(key => {
			if (updateConfig.hasOwnProperty(key)) {
				config[key] = updateConfig[key]
			}
		})
	}

	function UPLOAD(path, body) {
		const url = pathToUrl(path)
		const request = createPostRequest(body)
		return POST_WITH_PROGRESS(url, request, processResponse)
	}

	function DOWNLOAD(path, body) {
		const url = pathToUrl(path)
		const request = createPostRequest(body)
		return DOWNLOAD_VIA_POST(url, request, processResponse)
	}

	async function POST(path, body) {
		const url = pathToUrl(path)
		const request = createPostRequest(body)
		const response = await doFetch(url, request)
		// https://wts-jira.wellsfargo.com/browse/EFMYA-7869
		// hack because SiteMinder intermittently drops session
		const redirectUrl = redirect(response)
		if (redirectUrl) {
			return _onRedirectCallback(redirectUrl)
		}
		return processResponse(response)
	}

	function pathToUrl(path) {
		if (/^https?:\/\//.test(path)) {
			return path
		}
		const isRootRelativePath = /^\//.test(path)
		const appContext = isRootRelativePath ? '' : _context
		const options = { leadingSlash: true }
		return urlJoin(_origin, appContext, path, options)
	}

	function createPostRequest(body, timestamp = getTimestamp()) {
		const headers = new Headers()
		const append = headers.append.bind(headers)

		if (_authorizationHeaderValueSet) {
			append('Authorization', _authorizationHeaderValue)
		} else {
			scrapeAndAppendHeader(config.authHeaderNameId, config.authHeaderValueId, append)
		}

		scrapeAndAppendHeader(config.csrfHeaderNameId, config.csrfHeaderValueId, append)

		append('WF-senderHostName', config.senderHostName)
		append('WF-senderMessageId', config.senderMessageId)
		append('WF-creationTimeStamp', timestamp)
		append('WF-senderApplicationId', config.senderApplicationId)

		if (body instanceof FormData) {
			// do not set content type bc FormData object handles it
			// append('Content-Type', 'multipart/form-data')
		} else {
			append('Content-Type', CONTENT_TYPE)
		}

		return {
			method: 'POST',
			headers: headers,
			cache: 'default',
			credentials: 'same-origin',
			body: serializeRequestBody(body)
		}
	}

	return {
		setOrigin,
		setContext,
		setAppContext,
		setAuthToken,
		setOnRedirectCallback,
		setConfiguration,
		createPostRequest,
		pathToUrl,
		UPLOAD,
		DOWNLOAD,
		POST
	}
}
